import { number, object, string } from 'yup'

import { stripeValidation, testPhoneNumber } from './validation.methods'
import { formatMessage } from './validation.utils'

export const email = string()
  .email(formatMessage({ defaultMessage: 'Invalid email' }))
  .max(256, formatMessage({ defaultMessage: 'Email is too long!' }))
  .required(formatMessage({ defaultMessage: 'Email is required' }))

export const password = string()
  .matches(/^.*(?=.*\d)(?=.*[A-Z])((?=.*[a-z]){1}).*$/, {
    message: formatMessage({
      defaultMessage:
        'The password must be between 8 and 50 characters long, contain uppercase, lowercase letters and numbers'
    })
  })
  .min(8, formatMessage({ defaultMessage: 'Password is too short!' }))
  .max(50, formatMessage({ defaultMessage: 'Password is too long!' }))
  .required(formatMessage({ defaultMessage: 'Password is required' }))

export const phone = string().test(testPhoneNumber())

export const card_name = string()
  .trim()
  .required(
    formatMessage({ defaultMessage: 'Name on Card is a required field' })
  )
  .max(256, formatMessage({ defaultMessage: 'Max 256 characters' }))
  .nullable()

export const card_number = object()
  .nullable()
  .test(
    'cardNumber',
    formatMessage({ defaultMessage: 'Card Number is a required field' }),
    stripeValidation
  )

export const card_exp = object()
  .nullable()
  .test(
    'cardExpiration',
    formatMessage({ defaultMessage: 'Expiration Date is a required field' }),
    stripeValidation
  )

export const card_cvc = object()
  .nullable()
  .test(
    'cardCVC',
    formatMessage({ defaultMessage: 'CVC/CVV Code is a required field' }),
    stripeValidation
  )

export const quantity = number()
  .max(
    9999999999,
    formatMessage({
      defaultMessage: 'Quantity can have up to 10 digits'
    })
  )
  .min(
    1,
    formatMessage({
      defaultMessage: 'Please enter a min quantity 1'
    })
  )
  .typeError(
    formatMessage({
      defaultMessage: 'Please enter only an integer number'
    })
  )
