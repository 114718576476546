// TODO: better use lazy for parsePhoneNumber on WEB but now crushed APP
import { parsePhoneNumber } from 'react-phone-number-input'
import { TestConfig, TestContext } from 'yup'

import { UploadValueModel } from 'app/components/fields/UploadFields/UploadField/UploadField.types'
import { dateDifferenceToToday } from 'app/utils/helpers/functions.helpers'
import { isOfType } from 'app/utils/helpers/type.helpers'

import { formatMessage } from './validation.utils'

export const testPasswordConfirmation = ({
  message,
  fieldName = 'password'
}: { message?: string; fieldName?: string } = {}): TestConfig => {
  const config: TestConfig = {
    name: 'passwordConfirmation',
    message:
      message ||
      formatMessage({ defaultMessage: 'Confirm password is incorrect' }),
    test: function fn(value) {
      const password = this?.parent?.[fieldName]
      if (!value && !password) return true

      return value === password
    }
  }

  return config
}

export const testPhoneNumber = (
  name = 'phoneNumber',
  message = 'Phone number is invalid'
): TestConfig => {
  return {
    message:
      formatMessage({ defaultMessage: 'Phone number is invalid' }) || message,
    name,
    test: async (value = ''): Promise<boolean> => {
      if (!value) return true

      const currentValue = isOfType.string(value) ? value : String(value)

      const phoneNumber = parsePhoneNumber(currentValue)

      if (!phoneNumber?.isPossible()) return false
      if (!phoneNumber?.isValid()) return false

      const fullPhoneNumber = phoneNumber?.number
      const phoneNumberWithoutPlus = fullPhoneNumber.startsWith('+')
        ? fullPhoneNumber.slice(1)
        : fullPhoneNumber

      if (
        phoneNumberWithoutPlus.length < 7 ||
        phoneNumberWithoutPlus.length > 15
      )
        return false

      return !!value.toString().trim()
    }
  }
}

export const testVerificationCode = (): TestConfig => {
  return {
    message: formatMessage({ defaultMessage: 'Verification code is invalid' }),
    name: 'verificationCode',
    test: (value, { createError }) => {
      const currentValue = isOfType.string(value) ? value : String(value)
      const transformValue = currentValue?.replace(/_/g, '') || ''
      const testValue = transformValue.replace(' ', '')

      if (testValue && testValue?.length > 0 && testValue?.length < 6) {
        return createError({
          message: formatMessage({ defaultMessage: 'Type a valid code' })
        })
      }

      return true
    }
  }
}

export const testFiles = ({
  name = 'files',
  message = 'Files is required',
  maxCount = 6,
  isNotRequired = false
}): TestConfig => {
  return {
    message: formatMessage({ defaultMessage: 'Files is required' }) || message,
    name,
    test: async (values, { createError }): Promise<any> => {
      const currentValues = isOfType.array(values) ? values : []

      const files = currentValues?.filter(
        (file: UploadValueModel) => !file._destroy
      )

      if (files?.length === 0 && !isNotRequired) {
        return createError({
          message: formatMessage({ defaultMessage: 'Files is required' })
        })
      }

      if (files?.length >= 1 && files?.length <= maxCount) {
        return true
      }

      if (isNotRequired && files?.length <= maxCount) {
        return true
      }

      if (files?.length > maxCount) {
        const message = formatMessage(
          {
            defaultMessage: 'Too many files max count {maxCount}'
          },
          { maxCount }
        )

        return createError({ message })
      }
    }
  }
}

export const testCheckFileSize = ({ size }: { size: number }): TestConfig => {
  const config: TestConfig = {
    name: 'checkFileSize',
    test: function fn(value, { createError }) {
      if (value) {
        if (Array.isArray(value)) {
          const notValidFile = value?.find((file) => {
            const fileSize = file.size / 1024 / 1024

            if (fileSize >= size) {
              return true
            }

            return false
          })

          if (notValidFile) {
            return createError({
              message: formatMessage(
                {
                  defaultMessage: 'Size must not be greater than {size}MB'
                },
                { size }
              )
            })
          }
        } else {
          if (!!value && isOfType.object(value) && 'size' in value) {
            const fileSize = value.size / 1024 / 1024

            if (fileSize >= size) {
              return createError({
                message: formatMessage(
                  {
                    defaultMessage: 'Size must be less then {size}MB'
                  },
                  { size }
                )
              })
            }
          }
        }
      }

      return true
    }
  }

  return config
}

export const testListingAddressCheck = ({
  name,
  message
}: {
  name: string
  message: string
}): TestConfig => {
  return {
    name,
    message,
    test: (value) => {
      if (
        isOfType.object(value) &&
        isOfType.string(value?.value) &&
        value?.value
      ) {
        return true
      }
      if (
        isOfType.object(value) &&
        isOfType.object(value?.value) &&
        value?.value
      ) {
        return true
      }

      return false
    }
  }
}

export const stripeValidation = (value, context) => {
  if (!value || value?.empty) {
    return false
  }

  const message = (value?.error as any)?.message

  if (message) {
    return context.createError({ message })
  }

  return true
}

export const testWarrantyValidUntil = ({
  name,
  message
}: {
  name: string
  message: string
}): TestConfig => {
  return {
    name,
    message,
    test: (value) => {
      const difference = dateDifferenceToToday(String(value))

      return difference >= 1
    }
  }
}

export const validateBusinessAddressGeosuggest = (
  address,
  context: TestContext
) => {
  const requiredFields = [
    'street_no',
    'street_name',
    'city',
    'country',
    'postal_code'
  ]

  const isAnyFieldMissing = requiredFields.some((field) => !address[field])
  const isAllFieldsMissing = requiredFields.every((field) => !address[field])
  const hasBusinessAddressSuggest = Object.hasOwn(
    address,
    'business_address_suggest'
  )
  const showRequiredError = hasBusinessAddressSuggest
    ? isAllFieldsMissing || !address?.business_address_suggest
    : isAllFieldsMissing

  if (showRequiredError) {
    return context.createError({
      message: formatMessage({ defaultMessage: 'Address is required' }),
      path: 'business_address_suggest'
    })
  }

  if (isAnyFieldMissing) {
    return context.createError({
      message: formatMessage({ defaultMessage: 'Address is not valid' }),
      path: 'business_address_suggest'
    })
  }

  return true
}

export const validateDispatchAddressGB = ({
  name,
  message,
  isOptional
}: {
  name: string
  message: string
  isOptional?: boolean
}): TestConfig => {
  return {
    name,
    message,
    test: (value) => {
      if (isOptional && !value) return true

      if (isOfType.object(value)) {
        if (isOfType.object(value?.value)) {
          if (value?.value?.country !== 'GB') return false

          return true
        }

        if (isOptional && !value?.value) {
          return true
        }
      }

      return false
    }
  }
}
