import { FormattedMessage, MessageDescriptor } from 'react-intl'
import { type AnySchema, ValidationError } from 'yup'
import type Lazy from 'yup/lib/Lazy'

export const getValidValuesBySchema = (
  schema: AnySchema | Lazy<AnySchema>,
  values: unknown,
  options?: { stripUnknown: boolean }
) => {
  const stripUnknown = options?.stripUnknown || true

  try {
    const validValues = schema.validateSync(values, {
      abortEarly: false,
      stripUnknown
    })

    return validValues
  } catch (error) {
    if (error instanceof ValidationError) {
      const allErrorsKeys = error.inner.map(
        (currentValidation) => currentValidation.path
      )

      const newValues = schema.cast(values, { stripUnknown })

      const validValues = Object.entries(newValues).reduce(
        (acc, [key, value]) =>
          allErrorsKeys.includes(key) ? acc : { ...acc, [key]: value },
        {}
      )

      return validValues
    } else {
      throw error
    }
  }
}

type MessageFormatPrimitiveValue = string | number | boolean | null | undefined

export const formatMessage = (
  message: MessageDescriptor,
  values?: Record<string, MessageFormatPrimitiveValue>
) => {
  return (
    <FormattedMessage {...message} values={values} />
  ) as unknown as string
}
