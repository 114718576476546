import { ReactElement } from 'react'

import toast, { ToastOptions } from 'react-hot-toast'

import AlertBidAttention from 'app/components/common/AlertBidAttention'

const toastOptions: Record<string, ToastOptions> = {
  success: {
    style: {
      border: '1px solid #D6DCE9',
      borderRadius: '16px',
      padding: '12px'
    },
    iconTheme: { primary: '#00EA90', secondary: '#fff' },
    position: 'top-left'
  },
  error: {
    style: {
      border: '1px solid #DCE0EB',
      borderRadius: '16px',
      padding: '12px',
      maxWidth: '500px'
    },
    position: 'top-left',
    iconTheme: { primary: '#FF4969', secondary: '#fff' }
  }
}

const Alert = {
  success: (message: string, options?: ToastOptions): void => {
    toast.success(message, { ...toastOptions.success, ...options })
  },
  warning: (message: string): void => {
    toast(message, { position: 'top-left' })
  },
  info: (message: string): void => {
    toast(message, { position: 'top-left' })
  },
  error: (message: string | ReactElement, buttons?: unknown[]): void => {
    toast.error(message, { ...toastOptions.error })
  },
  bidAttention: (): void => {
    toast((t) => <AlertBidAttention onClose={() => toast.dismiss(t.id)} />, {
      duration: Infinity
    })
  }
}

export default Alert
