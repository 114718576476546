import { FormattedMessage } from 'react-intl'

import CloseIcon from 'app/assets/icons/close-icon.svg'
import AttentionBidIcon from 'app/assets/icons/listing/attention-bid.svg'
import Button from 'app/components/base/Button'
import IconButton from 'app/components/base/IconButton'
import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'

interface AlertBidAttentionProps {
  onClose: () => void
}

const AlertBidAttention = ({ onClose }: AlertBidAttentionProps) => {
  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{ position: 'relative', mt: 0.5 }}
    >
      <Stack direction="row" sx={{ mr: '16px !important' }}>
        <Stack sx={{ width: 40, height: 40, mr: 1 }}>
          <AttentionBidIcon />
        </Stack>

        <Stack direction="column" sx={{ mt: 1 }}>
          <Text variant="body1">
            <FormattedMessage defaultMessage="Your bid didn't surpass the current highest bid set by the automatic bidder" />
          </Text>

          <Button
            variant="contained"
            size="small"
            onClick={onClose}
            sx={{ width: 95, mt: 1 }}
          >
            <FormattedMessage defaultMessage="OK" />
          </Button>
        </Stack>
      </Stack>

      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', right: '-10px', top: '-16px' }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

export default AlertBidAttention
